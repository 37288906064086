@import '../../../styles/customMediaQueries.css';

.container {
  align-items: center;
  background-color: #faf8f5;
  border-radius: var(--borderRadiusExtraLarge);
  min-height: 320px;
  margin: 0 auto;
  max-width: 1200px;
  position: relative;

  @media (--viewportMedium) {
    height: 400px;
    width: 600px;
  }

  @media (--viewportLarge) {
    width: 800px;
  }

  @media (--viewportLargePlus) {
    width: 1080px;
  }
}

.textSection {
  flex: 1;
  max-width: 70%;
  padding: 0 0 24px 18px;
  position: absolute;

  @media (--viewportMedium) {
    max-width: 50%;
    padding: 0 0 24px 48px;
  }
}

.title {
  font-size: 24px;
  font-weight: var(--fontWeightRegular);
  line-height: 36px;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 78px;
  }
}

.subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    font-size: 25px;
    line-height: 30px;
  }
}

.namedLink {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.button {
  border-radius: 8px;
  border: 0;
  background-color: var(--colorDarkBeige);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  min-height: fit-content;
  outline: 0;
  transition: none;
  width: fit-content;

  &:hover {
    border: 0;
    box-shadow: none;
    outline: 0;
    text-decoration: none;
  }
}

.buttonIcon {
  fill: none;
  width: 20px;
  stroke: var(--colorForeground);
  margin-left: 12px;
}

.infoText {
  font-size: 14px;
  margin-bottom: 10px;
  color: #999;
}

.urlSection {
  display: flex;
  align-items: center;
}

.urlInput {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 14px;
  color: #333;
}

.copyButton {
  padding: 8px;
  border: none;
  background-color: #000;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.imageSection {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.phoneImage {
  border-radius: var(--borderRadiusExtraLarge);
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  width: 100%;
}
