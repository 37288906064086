@import '../../../styles/customMediaQueries.css';

.noImage {
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadiusLarge);
}

:global(.image-gallery) {
  height: 100%;
}

:global(.image-gallery-index) {
  background: rgba(36, 36, 36, 0.6);
  border-radius: 0.5rem;
  color: var(--colorWhite);
  height: 24px;
  font-size: 13px;
  line-height: 16px;
  padding: 5px 15px;
  position: absolute;
  left: 18px;
  bottom: 12px;
  width: fit-content;
  z-index: 4;
}

:global(.image-gallery-thumbnails-container) {
  text-align: left !important;
}
:global(.image-gallery-thumbnails) {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 0;

  width: 100vw;
  overflow: auto;

  @media (--viewportMedium) {
    width: calc(100vw - 48px);
  }
  @media (--viewportLarge) {
    width: unset;
  }
}

:global(.image-gallery-slides) {
}

:global(.image-gallery-slide-wrapper) {
  height: 100%;
}

:global(.image-gallery-content) {
  height: 100%;
  justify-content: center;
}

:global(.image-gallery-slide) {
  padding: 6px 6px 0;

  @media (--viewportLarge) {
    padding: 0;
  }
}

:global(.image-gallery-thumbnail) {
  text-align: left !important;
}

:global(.image-gallery-slide-wrapper) {
  max-height: 80vh;
}

:global(.fullscreen) {
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;

  & :global(.image-gallery-slide-wrapper) {
    background-color: transparent;
    border: none;
  }

  & :global(.image-gallery-thumbnails-container),
  & :global(.image-gallery-thumbnail) {
    text-align: center !important;
  }
}

:global(.react-photo-album--rows) {
  justify-content: flex-start !important;
}

.noImage {
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadiusLarge);
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item {
  border-radius: var(--borderRadiusLarge);
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  object-fit: cover;
}

:global(.fullscreen) {
  & .item {
    width: auto;
  }

  & .itemCentering {
    padding: 12px 0;
  }
}

.thumb {
  border-radius: var(--borderRadiusLarge);
  width: 100%;
}

.navLeft,
.navRight {
  position: absolute;
  width: 60px;
  border: 0;
  padding: 0;
  z-index: 1;
  bottom: 0;
  top: 0;

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.navLeft {
  left: 0;

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 0;

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  border-radius: 50%;

  background-color: var(--colorLightBeige);

  & svg {
    fill: none;
    stroke: var(--colorForeground);
  }
}

.openFullscreen {
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 6px;

  background-color: var(--colorBeige);
  border-radius: 0.5rem;

  cursor: pointer;
  transition: var(--transitionStyleButton);

  /* Ensure the button is on top of the navRight area */
  z-index: 1;

  &:hover {
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportLarge) {
    display: block;
    padding: 12px 18px;
  }
}

.openFullscreen svg {
  fill: none;
}

.openFullscreen span {
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  line-height: 16px;
  margin-left: 12px;
}

.desktopGallery {
  display: flex;
  justify-content: center;
}

.desktopGalleryContainer {
  @media (--viewportLarge) {
    height: 480px;
    max-width: 900px;
  }

  @media (--viewportLargePlus) {
    height: 600px;
  }
}

.desktopSingle {
  height: 720px;
  max-width: var(--contentMaxWidthLarge);
}

.ikImage,
.ikVideo {
  border-radius: var(--borderRadiusLarge);
  cursor: pointer;
  display: block;
  height: 100%;
  width: 100%;
}

.lb {
  max-height: 100%;
}

.lbImage,
.lbVideo {
  border-radius: var(--borderRadiusLarge);
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.fsMedia {
  height: auto;
  width: 100%;

  @media (--viewportLargePlus) {
    max-height: 80vh;
    width: auto;
  }
}

.mobileMediaContainer {
  border-radius: var(--borderRadiusLarge);
  display: block;
}

.mobileMediaContainerSingle {
  padding: 0 6px;
}

.ikFirstImage {
  border-radius: var(--borderRadiusLarge);
  cursor: pointer;
  margin-right: 6px;
  width: auto;

  @media (--viewportLarge) {
    max-height: 480px;
  }

  @media (--viewportLargePlus) {
    max-height: 600px;
  }
}

.desktopAlbum {
  margin-left: 12px;
  width: 100%;

  & > div {
    border-radius: var(--borderRadiusLarge);
    overflow-y: scroll;
    scroll-snap-type: y mandatory;

    @media (--viewportLarge) {
      height: 480px;
    }

    @media (--viewportLargePlus) {
      height: 600px;
    }

    & > div {
      scroll-snap-align: start;
    }
  }
}

.masonryAlbum {
  width: 100%;

  @media (--viewportLarge) {
    height: 480px;
  }

  @media (--viewportLargePlus) {
    height: 600px;
  }
}

.close {
  composes: marketplaceModalCloseStyles from global;

  background: var(--colorLightBeige);

  color: var(--colorForeground);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--colorBlack);
  }
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}
